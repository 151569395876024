.main {
  padding: 40px 50px;
  max-width: 900px;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .main {
    padding: 30px 20px;
  }
}

.main h1 {
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  color: #000000;
  margin: 46px 0 0;
}

.main h2 {
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: #000000;
  margin: 52px 0 0;
}

.main .text-left {
  text-align: left;
}

.main .margin-top {
  margin-top: 100px;
}

@media screen and (max-width: 600px) {
  .main h1 {
    margin: 26px 0 0;
    font-size: 28px;
  }

  .main h2 {
    margin: 32px 0 0;
    font-size: 20px;
  }

  .main .margin-top {
    margin-top: 30px;
  }  
}

.main .channels {
  margin: 60px auto 0;
  max-width: 575px;
  display: flex;
}

.main .channels .column {
  padding: 0 10px !important;
}

@media screen and (max-width: 600px) {
  .main .channels {
    margin: 25px auto 0;
    flex-direction: column;
    max-width: 170px;
  }

  .main .channels .column {
    margin: 30px auto;
    padding: 0 5px !important;
  }
}

.main .btn {
  background: #FC652D;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  padding: 10px 40px;
  display: block;
  margin: 20px 0 0;
  cursor: pointer;
}

.main .btn.btn-continue {
  margin: 95px auto 0;
  padding: 10px 135px;
  text-transform: uppercase;
}

.main .btn:hover {
  background: #CA5124;
}

.main .btn:focus {
  outline-color: #CA5124 !important;
}

@media screen and (max-width: 500px) {
  .main .btn {
    padding: 10px 5px;
    width: 100%;
    margin: 15px auto 0;
    font-size: 21px;
  }

  .main .btn.btn-continue {
    margin: 35px auto 0;
    padding: 10px 5px;
  }
}

.main .message {
  font-size: 14px;
  text-align: center;
  color: #4D4D4D;
  padding: 0;
  box-sizing: border-box;
  margin: 10px 0;
}

.main .message a {
  color: #4D4D4D;
  text-decoration: underline;
}
