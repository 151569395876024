body {
  margin: 0;
  padding: 0;
  font-family: Source Sans Pro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.main {
  padding: 40px 50px;
  max-width: 900px;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .main {
    padding: 30px 20px;
  }
}

.main h1 {
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  color: #000000;
  margin: 46px 0 0;
}

.main h2 {
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: #000000;
  margin: 52px 0 0;
}

.main .text-left {
  text-align: left;
}

.main .margin-top {
  margin-top: 100px;
}

@media screen and (max-width: 600px) {
  .main h1 {
    margin: 26px 0 0;
    font-size: 28px;
  }

  .main h2 {
    margin: 32px 0 0;
    font-size: 20px;
  }

  .main .margin-top {
    margin-top: 30px;
  }  
}

.main .channels {
  margin: 60px auto 0;
  max-width: 575px;
  display: -webkit-flex;
  display: flex;
}

.main .channels .column {
  padding: 0 10px !important;
}

@media screen and (max-width: 600px) {
  .main .channels {
    margin: 25px auto 0;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 170px;
  }

  .main .channels .column {
    margin: 30px auto;
    padding: 0 5px !important;
  }
}

.main .btn {
  background: #FC652D;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  padding: 10px 40px;
  display: block;
  margin: 20px 0 0;
  cursor: pointer;
}

.main .btn.btn-continue {
  margin: 95px auto 0;
  padding: 10px 135px;
  text-transform: uppercase;
}

.main .btn:hover {
  background: #CA5124;
}

.main .btn:focus {
  outline-color: #CA5124 !important;
}

@media screen and (max-width: 500px) {
  .main .btn {
    padding: 10px 5px;
    width: 100%;
    margin: 15px auto 0;
    font-size: 21px;
  }

  .main .btn.btn-continue {
    margin: 35px auto 0;
    padding: 10px 5px;
  }
}

.main .message {
  font-size: 14px;
  text-align: center;
  color: #4D4D4D;
  padding: 0;
  box-sizing: border-box;
  margin: 10px 0;
}

.main .message a {
  color: #4D4D4D;
  text-decoration: underline;
}

.channel .col {
  padding: 0 10px !important;
}

.channel {
  position: relative;
  border: 3px solid rgba(77, 77, 77, 0.7);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.channel img {
  display: block;
  width: 100%;
}

.channel.disabled {
  border: 3px solid rgba(77, 77, 77, 0.3);
  cursor: not-allowed;
}

.channel.disabled img {
  opacity: 0.5;
}

.channel .check {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
}

.channel.selected {
  border-color: #FF6915;
}

.channel.selected .check{
  display: inline-block;
}

.modal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.modal .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.7);
}

.modal .overlay .content {
  margin: 10% auto 0;
  background: #fff;
  border: #ddd solid 1px;
  border-radius: 8px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
  max-width: 750px;
  padding: 40px;
  box-sizing: border-box;
  z-index: 10;
  position: relative;
}

.modal .overlay .content .close {
  top: 10px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}    

.modal .overlay .content .close svg {
  height: 15px;
  width: 15px;
}  

.modal .overlay .content h1 {
  font-size: 28px;
  margin: 0 0 20px;
}

.modal .overlay .content p {
  font-size: 22px;
  margin: 0 0 10px;
}

.modal .overlay .content a {
  color: #FC652D;
  cursor: pointer;
  text-decoration: none;
}


@media screen and (max-width: 500px) {
  .modal .overlay .content {
    padding: 30px;
  }

  .modal .overlay .content h1 {
    font-size: 24px;
    margin: 0 0 18px;
  }
      
  .modal .overlay .content p {
    font-size: 18px;
    margin: 0 0 10px;
  }
}
.error {
  -webkit-flex: 70% 1;
          flex: 70% 1;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding: 40px 50px;
}

/* Header */

.app .header {
  background-color: #ffffff;
  width: 100%;
}

.app .links .assine{
  margin: 0 0 0 auto;
  padding: 3px 0px ;
}

.app .header hr{
  width: 97%;
  margin : auto;
  border-top: 1px solid #D6D6D6;
}

.app .header .links a{
  text-decoration: none;
  font-size: 0.9em;
  font-weight: 700;
  padding: 5px 15px ;
  border-radius: 0.5em;
}

.app .header .links .santa{
  color: #3d92b8;
}

.app .header .links .an{
  color: #145187;
}

.app .header .links .dc{
  color: rgba(1, 116, 241, 1);
}

.app .header .links .hora{
  color: #35991F;
}

.app .header .links .assine a{
  background: -webkit-linear-gradient(left, #ff4a5d 0%, #ff6915 100%);
  background: linear-gradient(to right, #ff4a5d 0%, #ff6915 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.app .header .container {
  margin: 0 auto;
  box-sizing: border-box;
  padding: 14px 25px;
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  max-width: 1200px;
  width: 90%;
}

@media screen and (max-width: 700px) {
  .app .header .container {
    padding: 12px 12px;
    width: 100%;
  }

  .app .header .links a{
    padding: 5px 5px;
  }
}

.app .header .logo svg {
  margin-top: 1em;
  fill: #2F3134;
  height: 35px;
}

.app .header .links {
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
}


.app .header .links li:first-child {
  padding-left: 0;
}

/* End Header */

/* Footer */

.app .footer {
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
}

.app .footer img {
  max-height: 33px;
  max-width: 95%;
  margin: 5px auto 30px;
}

.app .footer h1,
.app .footer p {
  color: #4d4d4d;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

@media screen and (max-width: 500px) {
  .app .footer {
    padding: 10px;
  }

  .app .footer img {
    margin: 55px auto 25px;
  }

  .app .header hr{
    width : 100%;
  }

}

/* End Footer */

#cta-button-mobile {
  display: none;
}

@media screen and (max-width: 600px) {
  #whatsapp {
    float: none;
    margin-right: unset;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
  }
 
  #cta-button-desk {
    display: none;
  }
  #cta-button-mobile {
    display: block;
  }
}
