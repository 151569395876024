.modal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.modal .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.7);
}

.modal .overlay .content {
  margin: 10% auto 0;
  background: #fff;
  border: #ddd solid 1px;
  border-radius: 8px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
  max-width: 750px;
  padding: 40px;
  box-sizing: border-box;
  z-index: 10;
  position: relative;
}

.modal .overlay .content .close {
  top: 10px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}    

.modal .overlay .content .close svg {
  height: 15px;
  width: 15px;
}  

.modal .overlay .content h1 {
  font-size: 28px;
  margin: 0 0 20px;
}

.modal .overlay .content p {
  font-size: 22px;
  margin: 0 0 10px;
}

.modal .overlay .content a {
  color: #FC652D;
  cursor: pointer;
  text-decoration: none;
}


@media screen and (max-width: 500px) {
  .modal .overlay .content {
    padding: 30px;
  }

  .modal .overlay .content h1 {
    font-size: 24px;
    margin: 0 0 18px;
  }
      
  .modal .overlay .content p {
    font-size: 18px;
    margin: 0 0 10px;
  }
}